// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-html-js": () => import("./../../../src/pages/404.html.js" /* webpackChunkName: "component---src-pages-404-html-js" */),
  "component---src-pages-about-people-jsx": () => import("./../../../src/pages/about/people.jsx" /* webpackChunkName: "component---src-pages-about-people-jsx" */),
  "component---src-pages-about-repertoire-jsx": () => import("./../../../src/pages/about/repertoire.jsx" /* webpackChunkName: "component---src-pages-about-repertoire-jsx" */),
  "component---src-pages-discover-jsx": () => import("./../../../src/pages/discover.jsx" /* webpackChunkName: "component---src-pages-discover-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-shop-cancelled-jsx": () => import("./../../../src/pages/shop/cancelled.jsx" /* webpackChunkName: "component---src-pages-shop-cancelled-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-shop-success-jsx": () => import("./../../../src/pages/shop/success.jsx" /* webpackChunkName: "component---src-pages-shop-success-jsx" */),
  "component---src-templates-articles-jsx": () => import("./../../../src/templates/articles.jsx" /* webpackChunkName: "component---src-templates-articles-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-pages-jsx": () => import("./../../../src/templates/pages.jsx" /* webpackChunkName: "component---src-templates-pages-jsx" */),
  "component---src-templates-people-jsx": () => import("./../../../src/templates/people.jsx" /* webpackChunkName: "component---src-templates-people-jsx" */),
  "component---src-templates-products-jsx": () => import("./../../../src/templates/products.jsx" /* webpackChunkName: "component---src-templates-products-jsx" */),
  "component---src-templates-projects-jsx": () => import("./../../../src/templates/projects.jsx" /* webpackChunkName: "component---src-templates-projects-jsx" */)
}

